


























import tablelisttab from "@/components/_universal/list-table-tab.vue";
import GlobalServices from "@/services/global";
import IModuleFilter from "@/interfaces/filters/ModuleFilters";
import MODULE from "@/constants/modules";
import ApplicationService from "@/services/application";
import ProjectServices from "@/services/projects";
const app = new ApplicationService();
let global = new GlobalServices();
let api = new ProjectServices();
//Standard Components
import EVENTS from "@/constants/events";
import listMixin from "@/mixin/list-mixin";
import APP from "@/constants/application";

export default {
  mixins: [listMixin],
  data() {
    return {
      listDataObj: {},
      EVENT_ACTION: {},
      dataObj: {},
      headerTitle: null,
      headerSubTitle: null,
      actionId: null,
      id: null,
      parentActionId: null,
      moduleName: null,
      myItem: null,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    onActionItemClicked(action) {
      //Pass action to global navigation action handler...
      app.actionNavigation(action);
    },
    getHeaderTitle(actionId) {
      switch (actionId) {
        case MODULE.CLIENTS.ActionId:
          return {
            headerTitle: `Clients > ${this.myItem && this.myItem.BusinessName ? this.myItem.BusinessName + " > " : ""}${this.capitalizeFirstLetter(this.moduleName)}`,
            headerSubTitle: `${this.myItem && this.myItem.PrimaryAddress ? this.myItem.PrimaryAddress : ""}`,
          };
        case MODULE.QUOTATION.ActionId:
          return {
            headerTitle: `Quote (${this.myItem && this.myItem.QutoteNumber ? this.myItem.QutoteNumber + ") > " : ""}${this.capitalizeFirstLetter(this.moduleName)}`,
            headerSubTitle: `AttentionTo: ${this.myItem && this.myItem.AttentionTo ? this.myItem.AttentionTo : ""}`,
          };
        case MODULE.PROJECTS.ActionId:
          return {
            headerTitle: `Work Order (${this.myItem && this.myItem.projectRefNumber ? this.myItem.projectRefNumber + ") > " : ""}${this.capitalizeFirstLetter(this.moduleName)}`,
            headerSubTitle: `Client: ${this.myItem && this.myItem.clientName ? this.myItem.clientName : ""}`,
          };

        default:
          return {
            headerTitle: `${this.capitalizeFirstLetter(this.moduleName)}`,
            headerSubTitle: "",
          };
      }
    },
    async loadData() {
      this.id = parseInt(this.$route.params.id);
      this.parentActionId = parseInt(this.$route.params.parentActionId);
      this.moduleName = this.$route.params.moduleName;
      this.actionId = parseInt(this.$route.params.actionId);
      if (this.parentActionId === MODULE.PROJECTS.ActionId) {
        this.myItem = await api.getProjectDetails(this.id);
      } else {
        this.myItem = await global.getEditDetails(this.parentActionId, this.id);
      }
      this.headerTitle = this.getHeaderTitle(this.parentActionId).headerTitle;
      this.headerSubTitle = this.getHeaderTitle(this.parentActionId).headerSubTitle;

      // set dataObj
      this.dataObj = {
        title: this.capitalizeFirstLetter(this.moduleName),
        data: this.myItem,
        actionButton: this.getActionButton(this.actionId, "new", APP.UI_TYPE.Modal),
        editButton: this.getActionButton(this.actionId, "edit", APP.UI_TYPE.Modal),
        UI_TYPE: APP.UI_TYPE.Modal,
        param: {
          id: this.id,
          parentActionId: this.parentActionId,
          actionId: this.actionId,
        },
        //Call Back function to load Equipment Type DropDown List Results
        uxdata: async (pageName: string): Promise<Object> => {
          let uxInventoryCategory = [];
          let uxClientLocation = [];
          if (this.moduleName === "equipment") {
            uxInventoryCategory = await global.getUXDropDown(MODULE.INVENTORY.UX.InventoryCategory, null, null, null);
            let moduleFilter: IModuleFilter = {
              clientId: this.myItem.id,
              projectId: 0,
            };
            //Only call location api if we can filter by client id
            if (moduleFilter.clientId) {
              uxClientLocation = await global.getUXDropDown(MODULE.CLIENTS.UX.Location, null, null, moduleFilter);
            }
          }
          let uxContacts = [];
          if (this.moduleName === "locations") {
            uxContacts = await global.getUXDropDown(MODULE.CONTACTS.ActionId, MODULE.LOCATION.ModuleId, null, {
              clientId: this.myItem.id,
              projectId: 0,
            });
          }

          return {
            uxInventoryCategory,
            uxClientLocation,
            uxContacts,
          };
        },
      };
      this.loadTable();
    },
    getActionButton(actionId, actionType, UI_MODAL_TYPE) {
      let menuTitle = null;
      let modalTitle = null;
      let eventType = null;
      let componentName = null;

      if (actionId === MODULE.EQUIPMENT.ActionId) {
        // Equipment
        if (actionType === "new") {
          menuTitle = "New Equipment";
          modalTitle = "New Equipment";
          eventType = this.EVENT_ACTION.EQUIPMENT;
          componentName = "NewEquipment";
        } else {
          menuTitle = "Edit Equipment";
          modalTitle = "Edit Equipment";
          eventType = this.EVENT_ACTION.EQUIPMENT;
          componentName = "NewEquipment";
        }
      }
      if (actionId === MODULE.PROJECTS.Equipment.ActionId) {
        // Equipment
        if (actionType === "new") {
          menuTitle = "New Equipment";
          modalTitle = "New Equipment";
          eventType = this.EVENT_ACTION.EQUIPMENT;
          componentName = "NewProjectEquipment";
        } else {
          menuTitle = "Edit Equipment";
          modalTitle = "Edit Equipment";
          eventType = this.EVENT_ACTION.EQUIPMENT;
          componentName = "NewProjectEquipment";
        }
      }
      if (actionId === MODULE.MATERIALS.ActionId) {
        // Equipment
        if (actionType === "new") {
          menuTitle = "New Service/Materials";
          modalTitle = "New Service/Materials";
          eventType = this.EVENT_ACTION.SERVICES_MATERIALS;
          componentName = "NewMaterial";
        } else {
          menuTitle = "Edit Service/Materials";
          modalTitle = "Edit Service/Materials";
          eventType = this.EVENT_ACTION.SERVICES_MATERIALS;
          componentName = "NewMaterial";
        }
      }
      if (actionId === MODULE.LOCATION.ActionId) {
        // Location
        if (actionType === "new") {
          menuTitle = "New Location";
          modalTitle = "New Location";
          eventType = this.EVENT_ACTION.LOCATION;
          componentName = "NewLocation";
        } else {
          menuTitle = "Edit Location";
          modalTitle = "Edit Location";
          eventType = this.EVENT_ACTION.LOCATION;
          componentName = "NewLocation";
        }
      }
      if (actionId === MODULE.TIMETRACKING.ActionId) {
        // TimeTracking
        if (actionType === "new") {
          menuTitle = "Log Time";
          modalTitle = "Log Time";
          eventType = this.EVENT_ACTION.TASK;
          componentName = "NewProjectTimeTracking";
        } else {
          menuTitle = "Edit Time Tracking";
          modalTitle = "Edit Time Tracking";
          eventType = this.EVENT_ACTION.TASK;
          componentName = "NewProjectTimeTracking";
        }
      }
      if (actionId === MODULE.TASKS.ActionId) {
        // Task
        if (actionType === "new") {
          menuTitle = "Assign Task";
          modalTitle = "Assign Task";
          eventType = this.EVENT_ACTION.TASK;
          componentName = "NewProjectTask";
        } else {
          menuTitle = "Edit Task";
          modalTitle = "Edit Task";
          eventType = this.EVENT_ACTION.TASK;
          componentName = "NewProjectTask";
        }
      }
      if (actionType === "new") {
        return {
          id: 1,
          actionId: actionId,
          eventType: eventType.New,
          menuTitle,
          modalTitle,
          saveButtonTitle: "Save",
          data: this.myItem,
          componentName,
          isInEditMode: false,
          url: "",
          icon: "fa fa-truck",
          UI_TYPE: UI_MODAL_TYPE,
          dataObj: null, //Will be set in the component
        };
      } else if (actionType === "edit") {
        return {
          id: 2,
          actionId: actionId,
          eventType: eventType.View,
          menuTitle,
          modalTitle,
          saveButtonTitle: "Save",
          data: this.myItem,
          componentName,
          isInEditMode: true,
          url: "",
          icon: "fa fa-truck",
          UI_TYPE: UI_MODAL_TYPE,
          dataObj: this.myItem, //Will be set in the component
        };
      }
      return null;
    },
    async loadTable() {
      //Set Header Properties to be passed in as prop values
      let param = {
        id: this.id,
        parentActionId: this.parentActionId,
        actionId: this.actionId,
      } as any;

      if (this.parentActionId === MODULE.QUOTATION.ActionId && this.actionId === MODULE.EQUIPMENT.ActionId) {
        // only call this at equipment in sales
        param.clientId = this.myItem.IntClientID;
        param.locationId = this.myItem.IntLocationID;
      }
      this.listDataObj = await global.getDataByActionId(param, true);
    },
  },
  components: {
    tablelisttab,
  },
  async mounted() {
    await this.loadData();
    //Reload Data every 5 seconds
    this.timer = setInterval(async () => {
      await this.loadTable();
    }, 5000);
  },
  created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;
  },
};
